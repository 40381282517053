import { fetchApi } from "@/utils/apiCall";

export const saveUnsaveAPI = async (actionName, entityIds, lang, type = "saved_property", groupId = '') => {
  try {
    return await fetchApi({
      lang,
      baseUrl: "NEXT_PUBLIC_AUCTION_API_URL_V2",
      subUrl: actionName,
      method: "POST",
      reqBody: JSON.stringify({
        userId: Number(window.localStorage.getItem("userId")) ?? null,
        entityIds: entityIds,
        type: type,
        groupId: groupId
      }),
    });
  } catch (error) {
    return error?.response
  }
};
