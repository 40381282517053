import React from 'react'
import css from './styles.module.scss'
const LottieLiveTag = () => {
  return (
    <div className={css.liveTagWrapper}>
      <div className={css.liveTag}></div>
    </div>
   )
}

export default LottieLiveTag
