import React, { useEffect } from 'react';
import styles from './style.module.scss';
import useTranslation from 'next-translate/useTranslation';
import IconoGraphy from '../Common/IconoGraphy';
import Popup from '@/hoc/components/Popup';
import { numberWithCommas } from '@/utils/utils';
import { useRouter } from 'next/router';

const AuctionRejectEditPopup = ({ isEditEnable, closeHandler=null, amount=0}) => {
  const { t, lang } = useTranslation('translations-auction');
  const router = useRouter();

  useEffect(()=> {
    document.body.overflow = 'hidden';
    document.body.style.position ='inherit'
    return (()=> {
      document.body.overflow = 'auto'
      document.body.style.position ='inherit'
    })
  },[])

  const renderSubTitle = () => {
    if (lang === 'en') {
        return (
        <>
            <div className={styles.Title}>{t('EditFlow.Edit_Title')}</div>
            <div className={`${styles.Title} ${styles.TitleMargin}`}>{t('EditFlow.Edit_subTitle')}</div>
        </>)
    }
    return <div className={`${styles.Title} ${styles.TitleMargin}`}>{t('EditFlow.Edit_Title')}</div>
  };

  const onCloseClicked = () => {
    if (isEditEnable?.callBackUrl) {
      router.push(isEditEnable?.callBackUrl)
      return ;
    }
    closeHandler()
  }

  return (
    <Popup onClose={onCloseClicked} isOutClickedAllowed={false} focusLock={false}>
      <div className={styles.editBox}>
        <div className={styles.warning_iconBox}>
          <IconoGraphy iconClass="icon-toast-warning" iconColor="color_Edit_warning" fontSize="f_48" />
        </div>
        {renderSubTitle()}
        {['reject', 'awaiting-rejected'].includes(isEditEnable?.status) && <p className={styles.text}>{t('EditFlow.Edit_Text')}</p>}
        <p className={`${styles.subText} `}>{t('EditFlow.Edit_Sub_Text')}</p>
        {amount > 0 && ['reject', 'awaiting-rejected'].includes(isEditEnable?.status) && <div className={styles.info_iconBox}>
          <IconoGraphy iconClass="icon-toast-info" iconColor="color_Info_blue" fontSize="f_16" />
          <span className={styles.info_strip}>{t('EditFlow.Edit_Refund_Text').replace('#', numberWithCommas(amount))}</span>
        </div>}
        <button className={styles.btnCustom} onClick={onCloseClicked}>
          {t('EditFlow.Edit_GotIt_btn')}
        </button>
      </div>
    </Popup>
  );
};

export default AuctionRejectEditPopup;