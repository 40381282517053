import React, { useState, useEffect } from 'react';
import IconoGraphy from '@/components/Common/IconoGraphy';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useMediaQuery } from 'react-responsive';
import styles from './tooltip_srp.module.scss';

/**
 * Author: Mahesh K
 * This component is used to show the dynamic tooltip and I have added some more functionality to make it more useful.
 * @param {boolean} srpWidth - A boolean value which will be used to make the tooltip arrow more dynamic.
 */

const TooltipRevamp_SRP = ({
  tooltipText,
  tooltipText2,
  lang,
  customiconWrapperClass = '',
  customContainerClass = '',
  iconClass = 'icon-tooltip',
  fontSize = 'f_16',
  iconColor = 'color_gallery_popup_close',
  offsetLTR = [30, 5],
  offsetRTL = [-30, 5],
  srpWidth = false,
  postionTop = false,
  tooltipCustomClass = '',
  pageType='srp'
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipTop, setTooltipTop] = useState(0); // Store the tooltip top position
  const [tooltipBottom, setTooltipBottom] = useState(0); // Store the tooltip bottom position
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isMobileLarge = useMediaQuery({ maxWidth: 767, minWidth: 376 });

  const commonHandler = (temp) => {
    setIsVisible(temp);
  };

  const determinePosition = () =>  {
    switch (true) {
      case postionTop:
        return 'top';

      case srpWidth:
        return 'bottom';
  
      case isMobile:
        return lang ? 'bottom-end' : 'bottom-start';
  
      case isMobileLarge:
        return 'bottom-start';
  
      default:
        return 'bottom';
    }
  }

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    // if user on mobile then tooltip will open by click and for desktop , hover
    trigger: isMobile? 'click' : 'hover',
    placement: determinePosition(),
    offset: lang ? offsetLTR : offsetRTL,
    closeOnOutsideClick: false,
    visible: isVisible,
    onVisibleChange: commonHandler,
  });

  // Get the tooltip position on each render and update the state accordingly
  useEffect(() => {
    //this is used to find if the tooltip position is top or bottom
    if (isVisible && getTooltipProps) {
      const { top, bottom } = getTooltipProps().style;
      setTooltipTop(top);
      setTooltipBottom(bottom);
    }
  }, [isVisible, getTooltipProps]);

  // used to stop redirecting on GDP , only for mobile users
  const stopMobilePropagation = (e)=>{
    if(!isMobile) return
    e.preventDefault()
  }

  // used to hide the tooltip while scrolling
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (isVisible) {
        setIsVisible(false)
      }
    })
  }, [isVisible])

  const getToolTipClassName = () => {
    // Get the tooltip arrow position based on the top and bottom values
    const isTopAuto = tooltipTop === 'auto'
    const isBottomAuto = tooltipBottom === 'auto'
    let tooltipArrowPosition

    if (isTopAuto) {
      tooltipArrowPosition = 1
    } else if (isBottomAuto) {
      tooltipArrowPosition = 0
    } else {
      tooltipArrowPosition = null
    }

    if(tooltipCustomClass) {
      return styles[tooltipCustomClass]
    } else if(tooltipArrowPosition === 1) {
      return styles.tooltipArrow_new1 
     } 
    return styles.tooltipArrow_new
  }

  const filedInfoClassName = pageType === 'srp' ? styles.fieldInfo : ''

  return (
    <>
      <div className={`${styles[customiconWrapperClass]} ${filedInfoClassName}`} ref={setTriggerRef} onClick={stopMobilePropagation}>
        <IconoGraphy iconClass={iconClass} fontSize={fontSize} iconColor={iconColor} />
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `${styles.tooltipContainer_new} ${styles[customContainerClass]}`,
          })}
        >
          <div>
            {tooltipText}
            <br />
            {tooltipText2}
          </div>
          <div
            {...getArrowProps({
              className: getToolTipClassName(),
            })}
          />
        </div>
      )}
    </>
  );
};

export default TooltipRevamp_SRP;